const defaultState = () => ({
    parts: [],
    test: null,
    currentQuestion: null,
    activeQuestionId: 0,
    testUrl: '',
    answersUrl: '',
    showSuccess: false,
    errors: '',
    teachers: [],
    courses: [],
    successful_points_max: null,
    max_free_points: null
});
export default defaultState;
